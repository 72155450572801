@import "./variables.scss";

body {
  margin: 0;
  font-family: $body-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  color: black;

  width: 100%;
  height: 100%;
  overflow: scroll;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $header-font;
}

.view {
  section {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 3.25em 0;
    font-size: 1.2em;
  }

  .content {
    width: 90%;
    display: flex;
    flex-direction: column;

    h3 {
      color: black;
    }

    @media screen and (min-width: $medium) {
      width: 80%;
    }

    @media screen and (min-width: $large) {
        width: 70%;
    }
  }

  .image-heading {
    // text-transform: uppercase;
    text-align: center;
    padding: 10px;
    color: white;
    background-color: rgba(0,0,0,0.5);

    @media screen and (min-width: $small) {
      font-size: 3em;
    }
    
    @media screen and (min-width: $large) {
      font-size: 4em;
      max-width: 60%;
    }
  }
}

.bg-fixed {
  min-height: 80vh;
  box-shadow: none;
  z-index: 1;
  background-size: cover;

  @media screen and (min-width: 1400px) {
    background-attachment: fixed;
  }
}


