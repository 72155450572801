@import "./../../variables.scss";

footer {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    background: black;
    color: white;
    border-top: 1px solid white;
    justify-content: space-between;

    .footer-nav {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 0 20px 20px;

        @media screen and (min-width: $x-small) {
            padding-bottom: 0;
            width: 50%;
        }
    }

    .colophon {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 0 20px;

        @media screen and (min-width: $x-small) {
            width: 50%;
        }
    }
    
    h4 {
        font-size: 1.5em;
        padding: 0 0 10px;
        margin: 0;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: block;

        li {
            padding: 0 20px 0 0;
            margin: 0;
            display: inline-block;
            
            a, a:visited {
                color: #ffffff;
                text-decoration: none;
                font-family: $header-font;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 0.9em;

                &:hover, &:active, &:focus {
                    text-decoration: underline;
                }
            }
        }
    }

    @media screen and (min-width: $x-small) {
        flex-direction: row;
    }
}