@import "./../../variables.scss";

.faces {
    background-image: url('./../../assets/IMG_0189.jpg');
}

.church {
    background-image: url('./../../assets/church.jpg');
    background-position: center center;
}

.about-us {
    background-color: darkgrey;
    color: black;

    .content {
        justify-content: flex-start;
    }

    .names {
        font-family: $header-font;
        font-weight: bold;
        line-height: 1.3;
        position: relative;
        font-size: 2em;

        @media screen and (min-width: $small) {
            font-size: 5rem;
        }
    }

    .ampersand {
        display: block;
        position: absolute;
        font-size: 1.5em;
        color: white;
        top: 15px;
        left: 25px;
        z-index: 1;

        @media screen and (min-width: $small) {
            top: 25px;
        }
    }

    .jenn-paul {
        z-index: 2;
        position: relative;
    }
}

.when-where {
    background-color: white;
    color: black;

    .content {
        justify-content: flex-end;
    }

    .location-date {
        font-family: $header-font;
        font-weight: bold;
        text-align: right;
        line-height: 1.5;
        font-size: 1.1em;

        @media screen and (min-width: $small) {
            font-size: 2.5rem;
        }

        @media screen and (min-width: $medium) {
            font-size: 3.5rem;
        }
    }

    .date, .city {
        display: block;
    }

    .city {
        color: darkgrey;
    }

    .date {
        font-size: 1.2em;
    }
}

.details {
    .content {
        padding-top: 20px;
        padding-bottom: 20px;

        @media screen and (min-width: $large) {
            padding-top: 60px;
            padding-bottom: 60px;
        }
    }

    a, a:visited {
        font-weight: bold;
        color: black;

        &:hover, &:active, &:focus {
            text-decoration: underline;
            color: darkgrey;
        }
    }
}