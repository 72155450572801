@import "./../../variables.scss";

.bg-fixed {
    &.san-francisco {
        background-image: url('./../../assets/golden_gate.jpg');
        background-position: center center;
        max-height: 50vh;
        min-height: 50vh;
    }
}

.bg-fixed {
    &.pac-heights {
        background-image: url('./../../assets/pac_heights.jpg');
        background-position: center center;
        max-height: 50vh;
        min-height: 50vh;
    }
}

.view {
    .schedule-details {
        background-color: white;
        color: black;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;

        @media screen and (min-width: $large) {
            padding-left: 10%;
            padding-right: 10%;
        }
    }
}


.schedule-section {
    text-align: center;
    position: relative;
    z-index: 1;
    vertical-align: top;
    width: 100%;

    @media screen and (min-width: $small) {
        display: table;
    }
}

.event-title {
    text-align: center;
    vertical-align: middle;
    padding-bottom: 20px;

    @media screen and (min-width: $small) {
        border-right: 2px solid darkgrey;
        padding: 20px;
        display: table-cell;
        width: 50%;
    }
}

.event-details {
    text-align: center;
    vertical-align: middle;
    padding-bottom: 50px;

    @media screen and (min-width: $small) {
        padding: 60px;
        display: table-cell;
        width: 50%;
    }

    address {
        font-style: normal;
    }

    a.directions {
        display: inline-block;
        color: white;
        background: black;
        border-color: white;
        border: 2px solid;
        font-family: $header-font;
        text-decoration: none;
        font-weight: bold;
        padding: 10px 15px;
        margin: 20px 0 0;
        font-size: 0.7em;
        text-transform: uppercase;

        &:hover {
            color: black;
            background: white;
            border-color: black;
        }
    }

    .event-note {
        font-style: italic;
        font-size: 0.8em;
        padding: 10px 0;
    }
}

.event-name {
    display: block;
    font-family: $header-font;
    font-weight: bold;
    font-size: 1.5em;
    padding-bottom: 20px;
}

.event-date, .event-time  {
    display: block;
    font-family: $header-font;
    font-size: 0.8em;
    text-transform: uppercase;
}

.event-date {
    padding-bottom: 15px;
}
