@import '../../../node_modules/bulma/sass/utilities/_all';

$header-font: 'Montserrat', sans-serif;
$body-font: 'Playfair Display', serif;

$family-sans-serif: $body-font;
$background: $white;
$text: $black;
$link: $black;
$label-color: $black;
$input-color: $black;
$input-placeholder-color: rgba($input-color, 0.3);
$input-hover-color: #363636;

@import '../../../node_modules/bulma/sass/form/_all';
@import '../../../node_modules/bulma/sass/elements/button';
@import '../../../node_modules/bulma/sass/elements/container';
@import '../../../node_modules/bulma/sass/elements/content';
@import '../../../node_modules/bulma/sass/layout/section';
@import '../../../node_modules/bulma/sass/grid/columns';
@import '../../../node_modules/bulma/sass/base/helpers';

a {
  color: $link;
  cursor: pointer;
  // text-decoration: none;
  strong {
    color: currentColor;
  }
  &:hover {
    color: $link-hover;
  }
}

.registry-list {
    list-style-type: none;

    li {
        &:before {
            content: "❤️";
            position: relative;
            display: inline-block;
        }

        button {
            vertical-align: baseline;
        }
    }
}

input,
textarea {
  box-sizing: border-box;
}

div.field {
  padding-bottom: 20px;
}

.Form {
  padding-bottom: 50vh;
}

.help {
  font-family: $header-font;
}

.bg-fixed {
  &.us_beach {
    background-image: url('./../../assets/IMG_5446.JPG');
    background-position: center center;
    max-height: 50vh;
    min-height: 50vh;
  }
}
