@import "./../../variables.scss";

header {
    text-align: center;
    padding: 0 0 20px;
    border-bottom: 2px solid white;
    background: black;
    color: white;

    h1 {
        font-size: 2.5em;
        padding: 20px 0 10px;
        margin: 0;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            padding: 0 20px;
            margin: 0;
            display: inline-block;
            
            a, a:visited {
                color: #ffffff;
                text-decoration: none;
                font-family: $header-font;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 0.9em;

                &:hover, &:active, &:focus {
                    text-decoration: underline;
                }
            }
        }
    }
}